.check-input-control {
  margin-bottom: 20px;

  input:checked {
    &~.checkbox {
      background: $blue-3 !important;
      border-color: $blue-3;

      &::after {
        border-color: $blue-3;
        background-color: $blue-3;
        content: "\eab2";
        font-family: 'Grayic';
        color: #fff;
        font-size: 11px;
        text-align: center;
        line-height: 15px;
        font-weight: 700;
      }
    }
  }

  .checkbox {
    position: relative;
    line-height: 1;
    min-width: 19px;
    max-width: 19px;
    min-height: 19px;
    max-height: 19px;
    border-radius: 3px;
    // background-color: #e7e9ed;
    border: 1px solid $gray-1;
    display: inline-block;
    cursor: pointer;

    &:after {
      content: "";
      color: $black;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      border: 1px solid #e2e4e8;
      background-color: #ffffff;
    }
  }
}

// Common CSS
.focus-reset:focus {
  box-shadow: none;
  outline: none;
}

// Form

.search-form-one {


  .filter-search-form-2 {
    @include desktops {
      height: 107px;
    }
  }

  .form-control {
    @include desktops {
      margin-left: 13px;
    }
  }

  .form-icon {
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translateY(-50%);

    @include desktops {
      left: 6%;
    }
  }

  .border-top-dotted {
    border-top: 2px dotted $gray-1;

    @include mobile {
      border-top: none;
    }
  }

  .hero-search-select {
    @include desktops{
      padding-left: 20px;
      margin-right: 15px;
    }
    .current {
      font-size: 16px;
      font-weight: 400;
      padding-left: 50px;
      margin-left: -20px;
      margin-top: 4px;

      @include mobile {
        border-left: 3px dotted $gray-1;
      }
    }

    .form-icon {
      left: 20px;

      @include desktops {
        left: 10px
      }
    }

    .list {
      width: 100%;
      border-radius: 5px;
      margin-top: 12px;
      li{
        padding: 5px 25px;
        transition-duration: 0.4s;
        &:hover{
          background-color: $primary;
          color: $white;
        }
      }
    }

    &::after {
      margin-right: 20px;
      width: 10px;
      height: 10px;
      border-color: $dark-cloud;
    }
  }

  .btn-hero-btn {
    border-radius: 5px;

    @include desktops {
      min-width: 288px;
      height: 107px;
    }
  }
}

.subscribe-inline-form {
  position: relative;

  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    border-radius: 0;

    @include mobile {
      min-height: 107px;
    }

    label {
      margin-bottom: 0 !important;
      margin-left: 17px;

      i {
        margin-left: 20px;
        margin-top: 6px;
        font-size: 21px;
      }
    }
  }

  .form-control {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0;
    border: none;
    color: #8c97ac;

    @include mobile {
      padding-right: 105px;
      min-height: 107px;
    }
  }

  .form-group {
    min-height: 65px;
  }

  button {
    background: $primary;
    font-weight: 500;
    padding-left: 18px;
    padding-right: 18px;

    width: 100%;

    @include mobile {
      margin-top: 0;
      min-height: 107px;
      min-width: 160px;
      position: absolute;
      top: 0px;
      right: 0;
      width: inherit;
    }
  }
}

.subscribe-inline-form-2 {
  position: relative;

  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    border-radius: 0;

    @include mobile {
      min-height: 73px;
    }

    label {
      margin-bottom: 0 !important;

      i {
        margin-left: 20px;
        margin-top: 6px;
        font-size: 21px;
        font-weight: 700;
        color: #8c97ac;
      }
    }
  }

  .form-control {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    border: none;
    min-height: 63px;
    color: #8c97ac;

    @include mobile {
      padding-right: 105px;
      min-height: 73px;
    }
  }

  button {
    font-weight: 500;
    padding-left: 18px;
    padding-right: 18px;
    width: 100%;
    min-height: 63px !important;

    @include mobile {
      min-width: 160px;
      position: absolute;
      top: 4px;
      right: 4px;
      width: inherit;
      border-radius: 5px;
    }
  }
}

.newsletter-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;

  .form-group {
    label{
      margin-left: 20px;
      margin-bottom: 0;
      margin-top: 3px;
      i{
        font-weight: 700;
        color: $bali-gray;
      }
    }
    background: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    border-radius: 5px;

    input {
      border: none;
      padding-left: 18px;
      color: $bali-gray;
    }
  }

  button {
    min-width: 107px !important;
    height: 55px !important;
    margin-left: 5px;
  }
}


// contact page
.top-contact-info {
  .border-md-right {
    @include desktops {
      border-right: 1px solid #eceff4;
    }
  }
}

.contact {
  textarea {
    min-height: 222px;
  }
}


.text-placeholder-bali-gray {
  &::placeholder {
    color: $bali-gray;
  }
}