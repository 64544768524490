
.letter-spacing-normal{
    letter-spacing: normal;
}

.letter-spacing-reset{
    letter-spacing: 1px;
}
.letter-spacing-5{
    letter-spacing: 5px;
}
.letter-spacing-n83{
    letter-spacing: -0.83px;
}
.line-height-20{
    line-height: 20px;
}
.line-height-26{
    line-height: 26px;
}

.line-height-34{
    line-height: 34px;
}
.line-height-84{
    line-height: 1.05;
}

.line-height-normal{
    line-height: normal;
}