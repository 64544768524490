

/* Border Color */
.border-golden-yellow {
    border-color: $golden-yellow !important;
}

.border-custom-white {
    border-color: rgba(236, 239, 244, 0.3) !important;
}


/*
.footer-social-link {
    li {
        position: relative;

        &:after {
            content: ".";
            position: absolute;
            left: -50%;
            top: -10px;
            font-size: 25px;
            transform: translateX(50%);
        }

        &:first-child {

            &:after {
                display: none;
            }
        }

    }

}
*/


.timeline-area {
    .single-widgets {
        @include mobile-lg {
            width: 50%;
            flex: 0 0 50%;
        }

        @include desktops {
            width: 420px;
            flex: inherit;
        }

        &:nth-child(3) {
            @include desktops {
                margin-top: 75px;
            }
        }

        &:nth-child(4) {
            @include desktops {
                margin-top: 145px;
            }
        }
    }
}

.hover-pointer{
    &:hover{
        cursor: pointer;
    }
}

div#loading {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9999999;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 750px;
    img{
        z-index: 9999;
    }
}