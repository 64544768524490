// Common CSS
.bg-images{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

// Background Images
.bg-img{
    // Use in Home Page 1
    &-1{
        background-image: url(/image/home-1/jpg/hero-bg-1.jpg);
    }
    &-2{
        background-image: url(/image/home-2/jpg/promo-bg-img.jpg);
    }

}


// Bg Gradient 

.bg-gradient{
    &-1{
        background-image: linear-gradient(142deg, #fad961 0%, #f76b1c 100%);
    }
    &-2{
        position: relative;
        &::before{
            content:"";
            position: absolute;
            left: 0;
            top: 0;
            background-image: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(18, 27, 33, 0.76) 100%);
            width: 50%;
            height: 100%;
        }
    }
}
// 
.opacity-5{
    opacity: 0.5;
}
.bg-white-op8 {
    background-color:rgba(255, 255, 255, 0.8);
}
.bg-blue-3-op5 {
    background-color: rgba(15, 83, 250, 0.5) !important;
}

.mix-blend-multiply{
    mix-blend-mode: multiply;
}